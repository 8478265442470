@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
} */

/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  min-height: 100dvh;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .card-wrapper-form {
    @apply relative overflow-hidden rounded-t-lg bg-gradient-to-b from-slate-700 to-slate-800;
  }

  .card-wrapper-image {
    @apply relative overflow-hidden rounded-lg bg-gradient-to-b from-slate-700 to-slate-800;
  }

  /* Gradient */
  .card-wrapper-form::before,
  .card-wrapper-image::before {
    background: conic-gradient(
      rgba(48, 74, 200, 0.4) 0deg,
      rgba(41, 226, 72, 0.4) 0deg,
      transparent 80deg
    );

    @apply absolute left-[-25%] top-[-65%] h-[250%] w-[150%] animate-border-spin content-[''];
  }

  /* Body */
  .card-content-form {
    @apply left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] rounded-t-lg bg-BLACK;
  }

  .card-content-image {
    @apply top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] rounded-lg bg-BLACK;
  }
}


/* Grdient Border for Investors Component */
.gradient-border-r::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2) 100%);

}
/* .gradient-border-l::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2) 100%);
} */


